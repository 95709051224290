<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 视频专区 -->
        <div class="videoregion">
            <div class="videobox">
                <div class="videocon">
                    <div class="videohead">
                        <div class="ntab">
                            <div class="ta" :class="videotypeactive==0?'active':''" @click.prevent="videotypebtn(0)">全部</div>
                            <div class="ta" :class="videotypeactive==item.Id?'active':''" @click.prevent="videotypebtn(item.Id)" v-for="(item,index) in videotypedata" :key="index">{{item.CategoryName}}</div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="clear"></div>
                    <div class="videolist">
                        <div class="vibox" v-for="item in videodata" :key="item.id">
                            <div class="box">
                                <div class="cimg" :style="{background:'url('+item.CoverImageFileUrl+') no-repeat center center / cover'}">
                                    <div class="btn" @click.prevent="videobtn(item.Id,item.VideoFilePath)"><i class="iconfont">&#xe606;</i></div>
                                </div>
                                <div class="vname nowrap">{{item.VideoName}}</div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="pages" @click.prevent="pagesbtn()">
                            <i class="iconfont" v-if="videodata.length==0&&pageIndex==1">&#xe67c;</i>
                            <span>{{pagetxt}}</span>
                        </div>
                    </div>
                    <div class="hotvideo">
                        <div class="hotborder">
                            <div class="rtit"><i></i><span>热门视频</span><div class="clear"></div></div>
                            <div class="rlist">
                                <div class="ra" v-for="(item,index) in hotvideodata" :key="index">
                                    <div class="ico"><i class="iconfont" v-if="index<3">&#xe64e;</i><span>{{index+1}}</span></div>
                                    <div class="txt nowrap" @click.prevent="videobtn(item.Id,item.VideoFilePath)">{{ item.VideoName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="layervideo" :class="layerclass" v-if="videoshow">
                <div class="layerbox" :class="layerboxclass">
                    <div class="cbox"><i class="iconfont" @click.prevent="videoclosebtn()">&#xe64d;</i></div>
                    <div class="vbox">
                        <video id="region_video" controls="controls" preload="auto" poster="" loop="loop" autoplay>
                            您的浏览器版本不支持 video 标签，不能播放视频，请升级浏览器。
                            <source :src="videourl" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import { getStore,setStore } from '@/utils/utils';
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            pageIndex:1,
            videoshow:false,
            videourl:"",
            videodata:[],
            layerclass:'',
            layerboxclass:'',
            videotypedata:[],
            videotypeactive:0,
            pagetxt:'',
            hotvideodata:[],
            HasNext:false
        }
    },
    mounted(){
        this.GetVideoCategoryList();
        this.GetVideoList();
        this.GetHotVideoList();
    },
    methods:{
        //获取视频类别
        GetVideoCategoryList(){
            API.Web.GetVideoCategoryList().then(res => {
                this.videotypedata = res.Data;
            });
        },
        //获取视频
        GetVideoList(){
            this.loading=true;
            let par = {};
            if(this.videotypeactive!==0){
                par = {pageIndex:this.pageIndex,pageSize:8,videoType:this.videotypeactive};
            }
            else{
                par = {pageIndex:this.pageIndex,pageSize:8};
            }
            API.Web.GetVideoList(par).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.videodata.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到视频信息！';
                }
                this.loading=false;
            });
        },
        //获取热门视频
        GetHotVideoList(){
            API.Web.GetVideoList({pageIndex:this.pageIndex,pageSize:12,IsHot:true}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.hotvideodata.push(f);
                    });
                }
            });
        },
        // 类别切换
        videotypebtn(id){
            this.pagetxt='';
            this.pageIndex=1;
            this.videodata=[];
            this.hotvideodata=[];
            this.videotypeactive=id;
            this.GetVideoList();
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetVideoList();
                }
            }else{
                if(this.newsdata.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        // 播放
        videobtn(id,url){
            this.layerclass='vlayershow';
            this.layerboxclass="vlayerboxshow";
            this.videoshow=true;
            this.videourl=url;
            //增加点击量
            let cdata = JSON.parse(getStore("VideoAccessData"));
            let fdata = JSON.parse(getStore("VideoAccessData"));
            if(fdata==null||fdata==''){
                this.AddVideoCount(id,cdata);
            }
            else{
                // 清理掉已过期的
                fdata.forEach(e => {
                    let num = ((new Date().getTime()-new Date(e.Time).getTime()) / 1000) / 60;
                    if(num>=5){
                        cdata = cdata.filter(f=>f.Id==e.Id);
                    }
                });
                // 判断当前Id是否在已清理后（未过期）的集合中
                let item = cdata.find(f=>f.Id==id);
                if(item==null||item==undefined){
                    //不在清理后（未过期）的集合中，增加点击量
                    this.AddVideoCount(id,cdata);
                }
            }
        },
        //增加点击量
        AddVideoCount(id,cdata){
            if(cdata==null||cdata==''){
                cdata = [{"Id":id,"Time":new Date()}];
            }
            else{
                cdata.push({"Id":id,"Time":new Date()});
            }
            setStore("VideoAccessData",cdata);
            API.Web.AddVideoCount({id:id});
        },
        // 关闭
        videoclosebtn(){
            this.layerclass='vlayerhide';
            this.layerboxclass="vlayerboxhide";
            let st = setTimeout(()=>{
                this.videourl="";
                this.videoshow=false;
                clearTimeout(st);
            },300);
        }
    }
}
</script>
<style lang="less" scoped>
.videoregion{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
    .videobox{
        max-width: 1420px;
        margin: auto;
        .videocon{
            margin: 0px 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .videohead{
                height: 50px;
                line-height: 50px;
                background-color: #f2f6fa;
                border-bottom: 1px solid #ebebeb;
                user-select: none;
                .ntab{
                    float: left;
                    height: 100%;
                    margin-left: 20px;
                    .ta{
                        height: calc(100% - 3px);
                        float: left;
                        color: #555;
                        font-size: 16px;
                        line-height: 50px;
                        padding: 0px 10px;
                        margin: 0px 2px;
                    }
                    .ta.active{
                        border-bottom: 3px solid rgba(var(--themecolor),0.5);
                    }
                    .ta:hover{
                        cursor: pointer;
                        border-bottom: 3px solid rgba(var(--themecolor),0.3);
                    }
                }
            }
            .videolist{
                width: calc(100% - 390px);
                float: left;
                margin-left: 10px;
                margin-bottom: 10px;
                .vibox{
                    width: calc(100% / 4);
                    height: 200px;
                    float: left;
                    .box{
                        margin: 10px 10px 0;
                        background-color: #fff;
                        position: relative;
                        overflow: hidden;
                        .cimg{
                            width: 100%;
                            height: 136px;
                            overflow: hidden;
                            position: relative;
                            .btn{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0px;
                                top: 0px;
                                z-index: 1;
                                text-align: center;
                                line-height: 136px;
                                background:rgba(0,0,0,0.4);
                                transition: all 0.3s;
                                -webkit-transition: all 0.3s;
                                i{
                                    color: #fff;
                                    font-size: 36px;
                                    opacity: 0.8;
                                    transition: opacity 0.3s;
                                    -webkit-transition: opacity 0.3s;
                                }
                            }
                        }
                        .vname{
                            height: 40px;
                            line-height: 40px;
                            color: #555;
                            font-size: 14px;
                            border: 1px solid #ebebeb;
                            border-top: 0px;
                            padding-left: 10px;
                            transition: border 0.3s;
                            -webkit-transition: border 0.3s;
                        }
                    }
                    .box:hover{
                        cursor: pointer;
                    }
                    .box:hover .vname{
                        border: 1px solid #b3b2b2;
                        border-top: 0px;
                        color: rgb(var(--themecolor));
                    }
                    .box:hover .cimg .btn{
                        background:rgba(0,0,0,0.1);
                    }
                    .box:hover .cimg .btn i{
                        animation:vanibtn 1.5s ease-in-out 0s infinite;
                        -webkit-animation:vanibtn 1.5 ease-in-out 0s infinite;
                        @-webkit-keyframes vanibtn {
                            0% {opacity: 0.4;color: #fff;}
                            50% {opacity: 1;color: #666;}
                            100% {opacity: 0.4;color: #fff;}
                        }
                        @keyframes vanibtn {
                            0% {opacity: 0.4;color: #fff;}
                            50% {opacity: 1;color: #666;}
                            100% {opacity: 0.4;color: #fff;}
                        }
                    }
                }
                .pages{
                    width: 100%;
                    min-height: 50px;
                    line-height: 50px;
                    color: #666;
                    font-size: 16px;
                    text-align: center;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    user-select: none;
                    .iconfont{
                        font-size: 50px;
                        display: block;
                        margin-top: 40px;
                        color: #9b9b9b;
                    }
                }
                .pages:hover{
                    color: rgb(var(--themecolor));
                    cursor: pointer;
                }
            }
            .hotvideo{
                float: right;
                width: 358px;
                margin: 10px 10px 0px;
                overflow: hidden;
                .hotborder{
                    border: 1px solid #ebebeb;
                    background-color: #fff;
                    padding-bottom: 1px;
                }
                .rtit{
                    border-bottom: 1px solid #ebebeb;
                    height: 40px;
                    line-height: 40px;
                    i{
                        float: left;
                        margin: 11px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }
                    span{
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }
                .rlist{
                    padding-top: 5px;
                    .ra{
                        margin-left: 14px;
                        height: 44px;
                        line-height: 44px;
                        .ico{
                            float: left;
                            width: 30px;
                            height: 100%;
                            position: relative;
                            margin-right: 12px;
                            i{
                                font-size: 30px;
                                color: rgb(var(--themecolor));
                            }
                            span{
                                font-size: 14px;
                                position: absolute;
                                top: 0px;
                                left: 11px;
                                color: #666;
                            }
                        }
                        .txt{
                            color: #555;
                            font-size: 16px;
                            float: left;
                            width: calc(100% - 56px);
                            transition: color 0.3s;
                            -webkit-transition: color 0.3s;
                        }
                    }
                    .ra:hover{
                        cursor: pointer;
                    }
                    .ra:hover .txt{
                        color: rgb(var(--themecolor));
                    }
                    .ra:nth-child(-n+3) .ico span{
                        color: #fff;
                    }
                    .ra:nth-child(1) .ico i{
                        color: #eb312d;
                    }
                    .ra:nth-child(2) .ico i{
                        color: #fe7002;
                    }
                    .ra:nth-child(3) .ico i{
                        color: #f7aa4c;
                    }
                }
            }
        }
    }
}
.layervideo.vlayershow{
    animation:vlayershow 0.3s ease 0s 1;
    -webkit-animation:vlayershow 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes vlayershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @keyframes vlayershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
.layervideo.vlayerhide{
    animation:vlayerhide 0.3s ease 0s 1;
    -webkit-animation:vlayerhide 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes vlayerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    @keyframes vlayerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
}
.layervideo{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    .layerbox.vlayerboxshow{
        animation:vlayerboxshow 0.3s ease 0s 1;
        -webkit-animation:vlayerboxshow 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes vlayerboxshow {
            from {top: -50%;}
            to {top: calc(50% - 277px);}
        }
        @keyframes vlayerboxshow {
            from {top: -50%;}
            to {top: calc(50% - 277px);}
        }
    }
    .layerbox.vlayerboxhide{
        animation:vlayerboxhide 0.3s ease 0s 1;
        -webkit-animation:vlayerboxhide 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes vlayerboxhide {
            from {top: calc(50% - 277px);}
            to {top: -50%;}
        }
        @keyframes vlayerboxhide {
            from {top: calc(50% - 277px);}
            to {top: -50%;}
        }
    }
    .layerbox{
        width: 950px;
        height: 554px;
        position: absolute;
        top: calc(50% - 277px);
        left: calc(50% - 475px);
        background-color: #fff;
        border-radius: 5px;
        .cbox{
            height: 1px;
            margin-bottom: 5px;
            margin-top: 10px;
            position: relative;
            i{
                display: block;
                width: 30px;
                height: 30px;
                position: absolute;
                top: -26px;
                right: -15px;
                text-indent: 9px;
                line-height: 32px;
                color: #666;
                font-size: 14px;
                border-radius: 20px;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
                background-color: #ffff;
            }
            i:hover{
                color: rgb(var(--themecolor));
                cursor: pointer;
                background-color: #d1d0d0;
            }
        }
        .vbox{
            margin: 10px 20px 0;
            overflow: hidden;
            height: 512px;
            video{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>